import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import KategoriTable from "./KategoriTable";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";

const Kategori = () => {
  const [data, setData] = useState([])
  useEffect(()=>{
    UseAPI('categories_of_questions',{},'GET').then((resp)=>{
      console.log(resp);
      setData(resp);
    });  
  },[])

  const handleDelete = (id) => {
    console.log("hapus",id);
    UseAPI(`categories_of_questions/${id}`,'','DELETE').then((resp) => {
      if (resp.status === 'ok') {
        setData(resp.data);
        swal(resp.pesan, {
          icon: "success",
        });    
        // window.location.reload();
      } else {
        swal(resp.pesan, {
          icon: "danger",
        });    
      }

    })

  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="Mata Kuliah"
        motherMenu="Master"
        pageContent="Mata Kuliah"
      />
      <div className="row">
		<KategoriTable 
      title="Daftar Mata Kuliah"
      data={data}
      onDelete={handleDelete}
    />
      </div>
    </Fragment>
  );
};

export default Kategori;
