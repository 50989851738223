import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { UseAPI } from '../../../services/UseAPI';
import { COLUMNS } from './Columns';
import PageTitle from '../../layouts/PageTitle';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import uuid from 'node-uuid'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FormElementsVertical from '../Question/FormElementsVerical';

function TryoutSoal() {
	const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS,[]);
	const { id } = useParams();
	useEffect(() => {
		UseAPI('tryout_questions/'+id,{},'GET').then((resp)=>{
			setData(resp)
			console.log(resp);
		})
	}, []);



	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {globalFilter, pageIndex} = state  

	const chackbox = document.querySelectorAll(".bs_exam_topper input");
	const chackboxFun = (type) => {
		// for (let i = 0; i < chackbox.length; i++) {
			// const element = chackbox[i];
			// if (!element.checked) {
			// 	motherChackBox.checked = false;
			// 	break;
			// } else {
			// 	motherChackBox.checked = true;
			// }

		// }
	};	

	const hapus = (tryout_id,question_id) => {
		console.log(tryout_id)	;
		UseAPI('tryout_questions/'+tryout_id,{},'DELETE').then((resp)=>{
			UseAPI('tryout_questions/'+id,{},'GET').then((resp)=>{
				setData(resp)
				console.log(resp);
			})
	
		})

	}

	return (
		<>
		<PageTitle activeMenu="Soal" motherMenu="Ujian" />
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Tambah (Jml Soal: {data.length})</h4>
				<span>
					<div className="d-flex align-items-center justify-content-between">
						<Dropdown className="dropdown">
							<Dropdown.Toggle as="div" className="btn-link i-false">
								<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
								<Dropdown.Item as={Link} to={`tambah/${id}`} className="dropdown-item">Klaim Soal</Dropdown.Item>
								<Dropdown.Item as={Link} to={`#`} className="dropdown-item">Reset Semua</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div> 					
				</span>
			</div>
			<div className="card-body">

				<div className="table-responsive">
					{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
					<table {...getTableProps()} className="table display">
						<thead>
						{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									<th style={{width:'15%'}}>										
										Aksi
									</th>

									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()} style={{width:column.width}}>
											{column.render('Header')}
											{column.canFilter ? column.render('Filter') : null}
										</th>
									))}
								</tr>
						))}
						</thead> 
						<tbody {...getTableBodyProps()} className="" >
						
							{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
										<td>
                                            <Button className="me-2" variant="warning btn-rounded" onClick={()=>hapus(row.original.id,id,row.original.question_text)}>
                                                <span className="btn-icon-start text-warning">
                                                    <i className="fa fa-trash color-warning" />
                                                </span>
                                                Hapus
                                            </Button>                                            
										</td>
										{row.cells.map((cell) => {
											if (cell.column.id !== 'question_text') {
												return <td key={uuid()} {...cell.getCellProps()}> {cell.render('Cell')} </td>
											} else {													
												return (
												<td key={uuid()}>
													{cell.row.original.question_text.replace(/<[^>]*>?/gm, '')}
												</td>											
												)

											}
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
					<div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div>
					<div className="text-center mb-3">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>

	);
}

export default TryoutSoal;
