import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import TryoutTable from "./TryoutTable";

const Tryout = () => {
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  useEffect(()=>{
    UseAPI('tryouts',{},'GET').then((resp)=>{
      console.log(resp);
      setData(resp.data);
      setField(resp.field);
    });  
  },[])

  const handleDelete = (id) => {
    swal({
      title: "Anda yakin ?",
      text: "Data yang dihapus adalah data yang berkaitan dengan ujian tersebut seperti soal, jawaban, dan hasil ujian",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UseAPI(`tryouts/${id}`,'','DELETE').then((resp) => {
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
            // window.location.reload();
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        })
    
      }
    })
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="Daftar"
        motherMenu="Ujian"
        pageContent="Ujian"
      />
      <div className="row">
        <TryoutTable
          title="Daftar Ujian"
          data={data}
          field={field}
          link={'tryout'}
          onDelete={handleDelete}
        />
      </div>
    </Fragment>
  );
};

export default Tryout;
