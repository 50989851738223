import React, { Fragment, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const UserRole = () => {
  const { id }  = useParams();
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState({role_id:1});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }  
  const handleSave = (e) => {
    console.log(inputs.role_id);
    e.preventDefault();
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI('user_roles',{user_id:id,role_id:inputs.role_id},'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }
  return (
    <Fragment>
      <PageTitle
        motherMenu="User"
        activeMenu="Role"
        pageContent="User"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tipe</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Tipe</label>
                    <div className="col-sm-9">
                        <select
                        defaultValue={"option"}
                        className="form-control form-control-lg"
                        name="role_id"
                        onChange={handleChange}
                        >
                        <option value='1'>Admin</option>
                        <option value='2'>User</option>
                        <option value='3'>Tamu</option>
                        </select>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default UserRole;
