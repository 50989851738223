import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import FormElements from "./FormElements";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const TryoutTipeEdit = () => {
    const { id } = useParams();
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    if (event) {
        console.log("event",event);
        const name = event.name;
        const value = event.value;    
        setInputs(values => ({...values, [name]: value}))
    } else {
        console.log(event)
    }
  }  

    const handleEditorChange = (event) => {
        if (event) {
            const name = event.target.id;
            const value = event.target.getContent();    
            setInputs(values => ({...values, [name]: value}))
        } else {
            console.log(event)
        }

    };
        

  const handleSave = (e) => {
    console.log(inputs);
    e.preventDefault();
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI('types_of_tryouts/'+id,inputs,'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            // UseAPI('tryouts/'+id,{},'GET').then((resp)=>{
            //     console.log("resp",resp);
            //     setField(resp.field);
            //     setData(resp.data[0]);
            // });    

            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }

  useEffect(()=>{
      UseAPI('types_of_tryouts/'+id,{},'GET').then((resp)=>{
          console.log("resp",resp);
          setField(resp.field);
          setData(resp.data);
      });    
  },[])
  

  
  

  return (
    <Fragment>
      <PageTitle
        motherMenu="Tryout"
        activeMenu="Tambah"
        pageContent="Tryout"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                    <FormElements
                        onChange={(e)=>handleChange(e.target)}
                        name={'type_name'}
                        label={'Nama'}
                        type={'text'}
                        value={data.type_name}
                    />


                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default TryoutTipeEdit;
