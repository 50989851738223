import { faAdd, faBars, faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import FormElementsVertical from "./FormElementsVerical";

const QuestionTable = (props) => {  
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div className="col-12">
      <div className="card" style={{ minHeight: "500px" }}>
        <div className="card-header">
            <div className="pe-3 mb-3">
                <h4 className="fs-20 text-black mb-1 font-w700">{props.title}</h4>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Cari..."
                    onInput={(e) => props.handleSearch(e.target.value)}
                />
            </div>
                
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <Dropdown className="dropdown">
                    <Dropdown.Toggle as="div" className="btn-link i-false">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item as={Link} to={`${props.link}/tambah`} className="dropdown-item">Baru</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`mata_kuliah`} className="dropdown-item">Mata Kuliah</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>            
        </div>
        <div className="card-body">
          <div className="w-100 res min-vh-30">
            <div id="kategoriTable" className="dataTables_wrapper">
              <Table striped hover size="sm" responsive="true"
                id="example5"
                className="display no-footer w-100"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: 50 }}
                    >
                      No.
                    </th>
                    <th
                      style={{ width: 150 }}
                    >
                      Mata Kuliah
                    </th>
                    <th
                      style={{ width: "5%" }}
                    >
                      Jawaban
                    </th>
                    <th
                      style={{ width: "60%" }}
                    >
                      Soal
                    </th>
                    <th
                      style={{ width: 5 }}
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((item,key)=>(
                  <tr key={key} role="row" className="even">
                    <td>{++key}</td>
                    <td>{item.category_name}</td>
                    <td>{item.correct_answer}</td>
                    <td>
                      <FormElementsVertical
                        type="textarea"
                        name="question_text"
                        value={item.question_text}
                        readOnly="true"
                        toolBar="false"
                        minHeight={50}
                      />
                    </td>
                    <td>
                      <Dropdown className="dropdown ms-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="btn-link i-false"
                          data-toggle="dropdown"
                        >
                        <FontAwesomeIcon icon={faBars} size="1x"/>  
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-left">
                          <Dropdown.Item as={Link} to={`${props.link}/edit/${item.id}`}><FontAwesomeIcon icon={faPen} className="me-2" /> Edit</Dropdown.Item>
                          <Dropdown.Item as={Link} to={'#'} onClick={() => props.onDelete(item.id)}><FontAwesomeIcon icon={faTrash} className="me-2" /> Hapus</Dropdown.Item>
                        </Dropdown.Menu> 
                      </Dropdown>
                    </td>
                  </tr>
                  ))}

                </tbody>
              </Table>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionTable;
