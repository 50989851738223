/// Menu
import Metismenu from "metismenujs";
import React, { Component, Fragment, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';

/// Image
//import user from "../../../images/user.jpg";
import profile from "../../../images/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faNewspaper } from "@fortawesome/free-solid-svg-icons";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const {
		iconHover
	} = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    const headerProfile = document.querySelectorAll(".header-profile2 a[class*='ai-icon']");
    const sidebarLinks = document.querySelectorAll(".metismenu a[class=''],.metismenu a[class*='ai-icon']");
    sidebarLinks.forEach((link) => { 
      link.addEventListener("click", () => {
        // Close the sidebar by removing the "menu-toggle" class
        if (aaa.classList.contains("menu-toggle")) {
          aaa.classList.remove("menu-toggle");
        }
      });
    });    
    headerProfile.forEach((linkprofile) => { 
      linkprofile.addEventListener("click", () => {
        console.log("satu");
        // Close the sidebar by removing the "menu-toggle" class
        if (aaa.classList.contains("menu-toggle")) {
          aaa.classList.remove("menu-toggle");
        }
      });
    });    
		
  }, []);
  /// Path
  let path = window.location.pathname;
  path = path.split('/')[2];
  // path = path.split('/').slice(2).join('/');
  /// Active menu
  let dashBoard = [
      "",
      "dashboard-dark"
    ],
    tryout = [
      "tryout",
    ],
    user = ['user'],
    question = ['question'],
    tryout_member = [
      "tryout_saya",
    ]

  ;
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userRole = userDetails ? userDetails.role : null;
  return (
    <div
      className={`dlabnav ${iconHover}`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
		  	<Dropdown className="dropdown header-profile2">
			  <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<div className="header-info2 d-flex align-items-center border">
				  <img src={profile} width={20} alt="" />
				  <div className="d-flex align-items-center sidebar-info">
					<div>
					  <span className="font-w700 d-block mb-2">{userDetails.name}</span>
					  <small className="text-end font-w400">{userDetails.role}</small>
					</div>
					<i className="fas fa-sort-down ms-4"></i>
				  </div>
				</div>
			  </Dropdown.Toggle>
				  <Dropdown.Menu align="right" className=" dropdown-menu dropdown-menu-end">
					<Link to="/app-profile" className="dropdown-item ai-icon">
					  <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
						width={18} height={18} viewBox="0 0 24 24" fill="none"
						stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
					  >
						<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
						<circle cx={12} cy={7} r={4} />
					  </svg>
					  <span className="ms-2">Profile </span>
					</Link>
					<LogoutPage />
				  </Dropdown.Menu>
			  </Dropdown> 
        <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#" >
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul >
                <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>
              </ul>
            </li>

          {userRole === 'Admin' && (
            <Fragment>
              <li className={`${user.includes(path) ? "mm-active" : ""}`}>
                <Link to="/user" className="ai-icon" >
                  <i className="fas fa-user-check"></i>
                  <span className="nav-text">User</span>
                </Link>
              </li>            
              <li className={`${tryout.includes(path) ? "mm-active" : ""}`}>
                <Link to="/tryout" className="ai-icon" >
                  <FontAwesomeIcon icon={ faNewspaper } className="text-white me-4" />
                  <span className="nav-text">Ujian</span>
                </Link>
              </li>          
              <li className={`${question.includes(path) ? "mm-active" : ""}`}>
                <Link to="/question" className="ai-icon" >
                  <FontAwesomeIcon icon={ faListCheck } className="text-white me-4" />
                  <span className="nav-text">Bank Soal</span>
                </Link>
              </li>
            </Fragment>            
          )}
          {userRole === 'User' && (
            <Fragment>
              <li className={`${tryout_member.includes(path) ? "mm-active" : ""}`}>
                <Link to="/tryout_saya" className="ai-icon" >
                  <FontAwesomeIcon icon={ faNewspaper } className="text-white me-4" />
                  <span className="nav-text">Ujian</span>
                </Link>
              </li>          
            </Fragment>
          )}
          


        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
