import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS_TRYOUT_HASIL = [
	{
		Header : 'Soal',
		accessor: 'question_text',
		Filter: ColumnFilter,
		width:'50%'
	},
	{
		Header : 'Benar',
		accessor: 'benar',
		Filter: ColumnFilter,
		width:'20%'
	},
	{
		Header : 'Salah',
		accessor: 'salah',
		Filter: ColumnFilter,
		width:'20%'
	},

]
export const COLUMNS_TRYOUT_HASIL_PESERTA = [
	{
		Header : 'Nama Peserta',
		accessor: 'first_name',
		Filter: ColumnFilter,
		width:'50%'
	},
	{
		Header : 'NIT',
		accessor: 'phone_number',
		Filter: ColumnFilter,
		width:'20%'
	},
	{
		Header : 'Benar',
		accessor: 'benar',
		Filter: ColumnFilter,
		width:'15%'
	},
	{
		Header : 'Salah',
		accessor: 'salah',
		Filter: ColumnFilter,
		width:'15%'
	},
	{
		Header : 'Muat Ulang',
		accessor: 'refreshed',
		Filter: ColumnFilter,
		width:'20%'
	},
	{
		Header : 'Pindah Layar',
		accessor: 'leaved',
		Filter: ColumnFilter,
		width:'20%'
	},	

]
export const COLUMNS_TRYOUT_MONITORING = [
	{
		Header : 'Nama',
		accessor: 'nama',
		Filter: ColumnFilter,
		width:'40%'
	},
	{
		Header : 'NIT',
		accessor: 'nit',
		Filter: ColumnFilter,
		width:'20%'
	},
	{
		Header : 'Muat Ulang',
		accessor: 'refreshed',
		Filter: ColumnFilter,
		width:'20%'
	},
	{
		Header : 'Pindah Layar',
		accessor: 'leaved',
		Filter: ColumnFilter,
		width:'20%'
	},

]
export const COLUMNS_TRYOUT_HASIL_KATEGORI = [
	{
		Header : 'Nama Mahasiswa',
		accessor: 'first_name',
		Filter: ColumnFilter,
		width:'50%'
	},
	{
		Header : 'NIT',
		accessor: 'phone_number',
		Filter: ColumnFilter,
		width:'20%'
	},
]