export const downloadFile = async (url) => {
    try {
        const response = await fetch(`https://apps.unismuh.ac.id/examku/${url}`);
        if (!response.ok) throw new Error('Error when downloading file');
        
        const dataResponse = await response.blob();
        const urlFile = window.URL.createObjectURL(dataResponse);
        
        const link = document.createElement('a');
        link.href = urlFile;
        link.target = '_blank';
        
        // Generate a timestamp
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const filename = `download-${timestamp}${getFileExtension(url)}.xlsx`;
        
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        link.remove();
        window.URL.revokeObjectURL(urlFile);
    } catch (error) {
        console.error('Error when downloading file:', error);
    }
}

const getFileExtension = (url) => {
    const parts = url.split('.');
    return parts.length > 1 ? `.${parts.pop()}` : '';
}
