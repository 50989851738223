import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { UseAPI } from '../../../services/UseAPI';
import { COLUMNS_TRYOUT_HASIL } from '../../../global/Columns';
import PageTitle from '../../layouts/PageTitle';
import '../../../global/filtering.css';
import { v4 as uuid } from 'uuid'
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faDotCircle, faEye, faPrint, faUser } from '@fortawesome/free-solid-svg-icons';
import FormElementsVertical from '../Question/FormElementsVerical';
import { downloadFile } from '../../../services/Helpers';
import { Bar } from 'react-chartjs-2';
import { plugin } from 'postcss';
import { title } from 'process';
import  ChartDataLabels from 'chartjs-plugin-datalabels';

function TryoutHasil(props) {
	const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS_TRYOUT_HASIL,[]);
	const { id } = useParams();
	const [showTitle, setShowTitle] = useState(true);
	const [showDetail, setShowDetail] = useState(false);
	const [modalData, setModalData] = useState({});
	const [dataChart, setDataChart] = useState({});
	const [optionChart, setOptionChart] = useState({});
	const [pluginsChart, setPluginsChart] = useState([]);
	const tryout_id = id;
	useEffect(() => {
		UseAPI('tryouts/hasil/'+tryout_id,{},'GET').then((resp)=>{
			setData(resp)
			console.log(resp);
			
		})
	}, []);


	useEffect(() => {
		if (props.title === 'false') {
			setShowTitle(false);
		}
	}, []);



	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {pageIndex} = state  

	const detailSoal = (data) => {
		let labels = []
		let datasets = []
		let backgroundColor = []
		data.rekap.map((item)=>{
			if (item.option_label === item.correct_answer) {
				labels.push(' ✔ ' + item.option_text.replace(/<[^>]*>?/gm, ''));
				backgroundColor.push('rgba(106, 212, 126, 1)');
			} else {
				labels.push(item.option_text.replace(/<[^>]*>?/gm, ''));
				backgroundColor.push('rgba(209, 209, 209, 1)');			
			}
			datasets.push(item.answer_count);
		})
		const total = datasets.reduce((sum, value) => sum + value, 0);
		const dataSoal = {
			labels: labels,
			datasets: [
				{
					label: 'test',
					data: datasets,
					backgroundColor: backgroundColor,
					borderColor: backgroundColor,
					borderWidth: 1
				}
			]
		};
	
		const dataPilihan = {
			indexAxis: 'y', // This makes the bars horizontal
			scales: {
				x: {
					beginAtZero: true,
					grace: '5%'
				}
			},
			plugins: {
				legend: {
					display: false
				},
				datalabels: {
					anchor: 'end', // Position the label at the end of the bar
					align: 'end',  // Align the label to the end of the bar
					offset: 10,    // Offset the label by 10 pixels from the end
					formatter: function(value, context) {
						// Calculate percentage
						const percentage = value + ' (' + ((value / total) * 100).toFixed(0) + '%'+')';
						return percentage;
					},
					color: 'black', // Set label color
					font: {
						weight: 'bold'
					}
				}
			}
		};	

		const pluginTambahan = ChartDataLabels;

		
		setDataChart(dataSoal);
		setOptionChart(dataPilihan);
		setPluginsChart(pluginTambahan);
		
		setShowDetail(!showDetail)	
		setModalData(data);
		console.log(data);

		
	}

	const handleClose = () => setShowDetail(false);

	return (
		<>	
		{showTitle && (
			<PageTitle activeMenu="Peserta" motherMenu="Ujian" />
		)}	
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Analisis Soal</h4>
				<span>
					<button type="button" className="btn btn-outline-primary btn-sm" onClick={() => downloadFile('tryouts/' + tryout_id+ '/cetak-analisis-soal')}>
						<FontAwesomeIcon icon={faPrint} />{' '}
						Cetak
					</button>
				</span>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
					<table {...getTableProps()} className="table display">
						<thead>
						{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
                  <th>No.</th>
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()} style={{width:column.width}}>
											{column.render('Header')}
											{column.canFilter ? column.render('Filter') : null}
										</th>
									))}
                  <th>Aksi</th>
								</tr>
						))}
						</thead> 
						<tbody {...getTableBodyProps()} className="" >
						
							{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
                    <td>{row.index+1}</td>
										{row.cells.map((cell) => {
											if (cell.column.Header === 'Soal') {
												return <td key={uuid()} {...cell.getCellProps()}> <FormElementsVertical value={cell.value} type="textarea" readOnly="true" toolBar="false" minHeight="50" /> </td>
											} else {
												return <td key={uuid()} {...cell.getCellProps()}>{cell.render('Cell')}</td>
											}
										})}
										<td>
                      <span>
                        <div className="d-flex align-items-center justify-content-between">
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle as="div" className="btn-link i-false">
                              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              <Dropdown.Item as={Link} to={'#'} onClick={() => detailSoal(row.original)} className="dropdown-item">Detail</Dropdown.Item>
                              <Dropdown.Item as={Link} to={`../hasil/kategori/benar/${row.original.nomor_soal}/${id}`} className="dropdown-item">Benar</Dropdown.Item>
                              <Dropdown.Item as={Link} to={`kategori/salah/${row.original.nomor_soal}/${id}`} className="dropdown-item">Salah</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> 					
                      </span>

                      {/* <Button className="me-2" variant="warning btn-rounded" onClick={()=>daftarPeserta(row.original.id)}>
                          <span className="btn-icon-start text-warning">
                              <FontAwesomeIcon icon={faUser} />
                          </span>
                          Lihat
                      </Button>                                             */}
										</td>

									</tr>
								)
							})}
						</tbody>
					</table> 
					<div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div>
					<div className="text-center mb-3">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal show={showDetail} onHide={handleClose} size='lg'>
			<Modal.Header closeButton>
			<Modal.Title>Detail Analisis</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					modalData && 
					(
						<>
							<FormElementsVertical value={modalData.question_text} type="textarea" readOnly="true" toolBar="false" minHeight="50" />
							<h3>{modalData.benar} / {parseInt(modalData.benar)+parseInt(modalData.salah)} jawaban benar</h3>
							<Bar data={dataChart && dataChart} options={optionChart && optionChart} plugins={[pluginsChart]}/>						
						</>
					)
				}

			</Modal.Body>
		</Modal>		
	</>

	);
}

export default TryoutHasil;

