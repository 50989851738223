import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import TryoutTipeTable from "./TryoutTipeTable";

const TryoutTipe = () => {
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  useEffect(()=>{
    UseAPI('types_of_tryouts',{},'GET').then((resp)=>{
      console.log("types_of_tryouts",resp);
      setData(resp.data);
      setField(resp.field);
    });  
  },[])

  const handleDelete = (id) => {
    console.log("hapus",id);
    UseAPI(`types_of_tryouts/${id}`,'','DELETE').then((resp) => {
      if (resp.status === 'ok') {
        setData(resp.data);
        swal(resp.pesan, {
          icon: "success",
        });    
        // window.location.reload();
      } else {
        swal(resp.pesan, {
          icon: "danger",
        });    
      }
    })
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="Tipe"
        motherMenu="Tryout"
        pageContent="Tipe"
      />
      <div className="row">
            <TryoutTipeTable
            title="Daftar Jenis Tryout"
            data={data}
            field={field}
            link={'tipetryout'}
            onDelete={handleDelete}
            />
      </div>
    </Fragment>
  );
};

export default TryoutTipe;
