import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader, faBug, faBugs, faClock, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const Home = () => {
	const history = useHistory();
	const [userRole, setUserRole] = useState('');
	const [data, setData] = useState([]);

	useEffect(() => {
		const userDetails = JSON.parse(localStorage.getItem('userDetails'));
		if (userDetails && userDetails.role) {
			setUserRole(userDetails.role);
		} else {
			history.push('/login');
		}
	}, [history]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await UseAPI('beranda', {}, 'GET');
			setData(response);
		};

		fetchData(); // Fetch data on first mount
		const interval = setInterval(fetchData, 10000); // Fetch data every 10 seconds
		return () => clearInterval(interval); // Cleanup function to stop the interval on component unmount
	}, []);

	return(
		<>
			{userRole === 'Admin' && (
				<>	
					<div className='row'>
						{data && data.map((item, key)=>(
							<div className='col-12' key={key}>
								<div className="card">
									<div className="card-header py-3">
										<h4 className='mb-0'>{item.tryout_name}</h4>
										<span>
											<Button className="btn btn-sm btn-primary" onClick={() => history.push(`/tryout/detail/${item.id}`)}>Monitoring</Button>
										</span>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-3 col-sm-6 col-6">
												<div className="card overflow-hidden shadow">
													<div className="card-header border-0">
														<div className="d-flex">
																<span className="justify-content-center align-self-center mt-2">
																	<i className="fas fa-3x fa-users"></i>
																</span>
																<div className="invoices">
																	<h4>{item.total_peserta}</h4>
																	<span>Peserta</span>
																</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-xl-3 col-sm-6 col-6">
												<div className="card overflow-hidden shadow">
													<div className="card-header border-0">
														<div className="d-flex">
															<span className="justify-content-center align-self-center mt-2">
																<i className="fas fa-3x fa-layer-group"></i>
															</span>
															<div className="invoices">
																<h4>{item.total_soal}</h4>
																<span>Jumlah Soal</span>
															</div>
														</div>
													</div>
													
												</div>
											</div>
											<div className="col-xl-3 col-sm-6 col-6">
												<div className="card overflow-hidden shadow">
													<div className="card-header border-0">
														<div className="d-flex">
															<span className="justify-content-center align-self-center mt-2">
																<FontAwesomeIcon icon={faClock} size="3x" />
															</span>
															<div className="invoices">
																<h4>																		
																{item.time_remaining}
																</h4>
																<span>Sisa Waktu</span>
															</div>
														</div>
													</div>
													
												</div>
											</div>
											<div className="col-xl-3 col-sm-6 col-6">
												<div className="card overflow-hidden shadow">
													<div className="card-header border-0">
														<div className="d-flex">
															<span className="justify-content-center align-self-center mt-2">
																<FontAwesomeIcon icon={faBookOpenReader} size="3x" />
															</span>
															<div className="invoices">
																<h4>{item.cheating}	</h4>
																<span>Pelanggaran</span>
															</div>

														</div>
													</div>
												</div>
											</div>											
										</div>										
									</div>
									<div className='card-footer'>
										<div className='row'>
											<div className='col-12 col-md-3'>
												<Button className='btn btn-primary btn-sm'>
													Mulai : {item.start_datetime}
												</Button>
											</div>
											<div className='col-12 col-md-3'>
												<Button className='btn btn-primary btn-sm'>
													Akhir : {item.exam_time_finish}
												</Button>
											</div>
										</div>
									</div>

								</div>
							</div>
						))}

					</div>
				</>

			)}
			{userRole === 'User' && (
				<div className="user-dashboard">
					<h2>Welcome to your Dashboard</h2>
					<p>Here you can view your exam schedules and results.</p>
				</div>
			)}
		</>
	)
}
export default Home;
