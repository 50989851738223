import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import QuestionTable from "./QuestionTable";
const Question = () => {
  const [data, setData] = useState([]);
  const [dataAwal, setDataAwal] = useState([]);
  const [field, setField] = useState([]);

  const handleSearch = (searchTerm) => {
    // setData([])
    // console.log(searchTerm);
    // console.log(dataAwal);
    const filteredData = dataAwal.filter(item => item.category_name.toLowerCase().includes(searchTerm.toLowerCase()));
    // console.log(filteredData);
    setData(filteredData);
  };


  useEffect(() => {
    UseAPI('questions', {}, 'GET').then((response) => {
      console.log("questions", response);
      setDataAwal(response.data);
      setData(response.data);
      setField(response.field);
    });
  }, []);

  const handleDelete = (id) => {
    console.log("hapus", id);
    UseAPI(`questions/${id}`, '', 'DELETE').then((response) => {
      if (response.status === 'ok') {
        setData(response.data);
        swal(response.pesan, {
          icon: "success",
        });
      } else {
        swal(response.pesan, {
          icon: "danger",
        });
      }
    });
  };


  return (
    <Fragment>
      <PageTitle
        activeMenu="Daftar"
        motherMenu="Bank Soal"
        pageContent="Bank Soal"
      />
      <div className="row">
        <QuestionTable
          title="Daftar Soal"
          data={data}
          field={field}
          link={'question'}
          onDelete={handleDelete}
          handleSearch={handleSearch}
        />
      </div>
    </Fragment>
  );
};

export default Question;

