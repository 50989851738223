import React from 'react'
import { useEffect,useState,useRef } from 'react';
import Select from "react-select";
import JoditEditor from 'jodit-react';

// const getData = (element) => {
//     return  (element.datasource && element.datasource.masterdata && (
//         element.datasource.masterdata.map((item,key)=>{
//            setOptionData(current => [...current,"<option value=1>1</option>"])
//        })
//     ))

// }
const options = (props) => {
    const optiondata = [];
    props.datasource && props.datasource.masterdata.map((item)=>{
        optiondata.push({value:item.id,label:item.type_name})    
    })

    return optiondata
};



const FormElements = ( props ) => {    
    const [optionData,setOptionData] = useState([])
    const editor = useRef(null);
    const [content, setContent] = useState('');
    console.log("readOnly",props.readOnly)

    const [config, setConfig] = useState({
        placeholder: props.placeholder || '',        
        readonly: props.readOnly ? true : false,
        hidePoweredByJodit: true,
        toolbar: props.toolBar ? false : true,
        statusbar: false,
        safeMode: true,
        height: 50,
    });

    useEffect(()=>{
        props.datasource && props.datasource.masterdata.map((item)=>{
            // setOptionData(current => [...current,item])
            setOptionData(values => ({...values, [item]: item}))

            
        })
        console.log(optionData);
    },[])

  switch (props.type) {
    case 'select':
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                {/* <Select 
                    name={props.name}
                    onChange={props.onChange}
                    options={options(props)}
                    // className="form-control"

                />  */}
                <select
                    name={props.name}
                    onChange={props.onChange}
                    className="form-control"
                >
                    <option key={'0'} value={'0'}>Pilih Tipe</option>
                    {
                        options(props).map((item,key)=> {
                            if (parseInt(item.value) === parseInt(props.value)) {
                                return <option key={key} value={item.value} selected={'selected'}>{item.label}</option>
                            } else {
                                return <option key={key} value={item.value} >{item.label}</option>
                            }
                        })
                    }
                </select>
                </div>
            </div>    
        )        
        break;
    case 'textarea':
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                        <JoditEditor
                            ref={editor}
                            value={props?.value || content}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                            id={props.name}
                            name={props.name}
                        />               
                </div>
            </div>    
        )
        break;
    default:
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                <input
                    name={props.name}
                    type={props.type}
                    className="form-control"
                    placeholder={props.label}
                    onChange={props.onChange}
                    defaultValue={props.value}
                />
                </div>
            </div>    
        )
        break;
  }
}

export default FormElements
