import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

//Master
import Kategori from "./components/Master/Kategori/Kategori";
import KategoriDetail from "./components/Master/Kategori/KategoriDetail";
import KategoriTambah from "./components/Master/Kategori/KategoriTambah";

import Pengaturan from "./components/Master/Pengaturan/Pengaturan";

//user
import User from "./components/Master/User/User";
import UserEdit from "./components/Master/User/UserEdit";
import UserTambah from "./components/Master/User/UserTambah";
import UserRole from "./components/Master/User/UserRole";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Charts
import ChartJs from "./components/charts/Chartjs";


import { ThemeContext } from "../context/ThemeContext";

/// TryOut
import Tryout from "./components/Tryout/Tryout";
import TryoutTambah from "./components/Tryout/TryoutTambah";
import TryoutEdit from "./components/Tryout/TryoutEdit";
/// Questions
import Question from "./components/Question/Question";
import QuestionTambah from "./components/Question/QuestionTambah";
import QuestionEdit from "./components/Question/QuestionEdit";
import TryoutSoal from "./components/TryoutSoal/TryoutSoal";
import TryoutSoalTambah from "./components/TryoutSoal/TryoutSoalTambah";
import TryoutTipe from "./components/Tryout/TryoutTipe";
import TryoutTipeEdit from "./components/Tryout/TryoutTipeEdit";
import TryoutTipeTambah from "./components/Tryout/TryoutTipeTambah";
import TryoutParticipants from "./components/TryoutParticipants/TryoutParticipants";
import TryoutParticipantsTambah from "./components/TryoutParticipants/TryoutParticipantsTambah";
import TryoutHasil from "./components/Tryout/TryoutHasil";
import TryoutHasilKategori from "./components/Tryout/TryoutHasilKategori";
import TryoutMonitoring from "./components/Tryout/TryoutMonitoring";
import TryoutDetail from "./components/Tryout/TryoutDetail";
import TryoutHasilPeserta from "./components/Tryout/TryoutHasilPeserta";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    ///master
    { url: "mata_kuliah", component: Kategori },
    { url: "mata_kuliah/edit/:id", component: KategoriDetail },
    { url: "mata_kuliah/tambah", component: KategoriTambah },
    { url: "pengaturan", component: Pengaturan },

    //user
    { url: "user", component: User },
    { url: "user/edit/:id", component: UserEdit },
    { url: "user/tambah", component: UserTambah },
    { url: "user/role/:id", component: UserRole },


    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    //tryout
    { url: "tryout", component: Tryout },
    { url: "tryout/tambah", component: TryoutTambah },
    { url: "tryout/edit/:id", component: TryoutEdit },
    { url: "tryout/hasil/:id", component: TryoutHasil },
    { url: "tryout/hasilpeserta/:id", component: TryoutHasilPeserta },
    { url: "tryout/detail/:id", component: TryoutDetail },
    { url: "tryout/monitoring/:id", component: TryoutMonitoring },
    { url: "tryout/hasil/kategori/:kategori/:id/:tryout_id", component: TryoutHasilKategori },
    { url: "tryout/soal/:id", component: TryoutSoal },
    { url: "tryout/soal/tambah/:id", component: TryoutSoalTambah },
    { url: "tipetryout", component: TryoutTipe },
    { url: "tipetryout/edit/:id", component: TryoutTipeEdit },
    { url: "tipetryout/tambah", component: TryoutTipeTambah },
    { url: "tryout/peserta/:tryout_id", component: TryoutParticipants },
    { url: "tryout/peserta/tambah/:tryout_id", component: TryoutParticipantsTambah },
		
    /// Question
    { url: "question", component: Question },
    { url: "question/tambah", component: QuestionTambah },
    { url: "question/edit/:id", component: QuestionEdit },


  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  console.log(path);

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 250 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>      
    </>
  );
};

export default Markup;
